import React from "react";
import { Link } from "react-router-dom";
import { useConnect } from "wagmi";

const ConnectWallet = (props) => {
  const { connectors, connect } = useConnect();
  const handleConnect = (connector) => {
    connect({ connector });
  };

  return (
    <div className="wallet">
      <img src="/images/logo.svg" alt="logo" />
      <br />
      <br />
      <br />
      <h2 className="pt-4">CLAIMS ARE NOW CLOSED</h2>
      {/* <p>Sign In with Your Wallet</p> */}
      <div className="text-center flex-wrap flex-md-nowrap  d-flex align-items-center gap-4 justify-content-center pt-5 wallets">
        {/* {connectors.map((connector) => (
          <div title={connector.name} className="card-custom">
            {connector.name === "MetaMask" ? (
              <svg
                width="56"
                height="57"
                viewBox="0 0 56 57"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M49.0087 6.59204L30.7996 20.1728L34.1669 12.1603L49.0087 6.59204Z"
                  fill="#E2761B"
                />
                <path
                  d="M6.97198 6.59204L25.0347 20.3015L21.8321 12.1603L6.97198 6.59204ZM42.457 38.0722L37.6073 45.5334L47.9837 48.4002L50.9668 38.2376L42.457 38.0722ZM5.05042 38.2376L8.01512 48.4002L18.3916 45.5334L13.5419 38.0722L5.05042 38.2376Z"
                  fill="#E4761B"
                />
                <path
                  d="M17.8061 25.4655L14.9146 29.8576L25.2178 30.3171L24.8518 19.1988L17.8061 25.4655ZM38.1747 25.4655L31.0374 19.0702L30.7995 30.3171L41.0845 29.8576L38.1747 25.4655ZM18.3917 45.5334L24.5773 42.5012L19.2335 38.3111L18.3917 45.5334ZM31.4034 42.5012L37.6074 45.5334L36.7472 38.3111L31.4034 42.5012Z"
                  fill="#E4761B"
                />
                <path
                  d="M37.6073 45.5333L31.4034 42.5011L31.8975 46.5625L31.8426 48.2716L37.6073 45.5333ZM18.3916 45.5333L24.1563 48.2716L24.1197 46.5625L24.5772 42.5011L18.3916 45.5333Z"
                  fill="#D7C1B3"
                />
                <path
                  d="M24.2478 35.6281L19.087 34.1027L22.7289 32.4304L24.2478 35.6281ZM31.7328 35.6281L33.2517 32.4304L36.9119 34.1027L31.7328 35.6281Z"
                  fill="#233447"
                />
                <path
                  d="M18.3917 45.5334L19.2701 38.0722L13.542 38.2376L18.3917 45.5334ZM36.7289 38.0722L37.6074 45.5334L42.457 38.2376L36.7289 38.0722ZM41.0845 29.8576L30.7995 30.317L31.7511 35.6281L33.2701 32.4304L36.9302 34.1027L41.0845 29.8576ZM19.0871 34.1027L22.7472 32.4304L24.2479 35.6281L25.2178 30.317L14.9145 29.8576L19.0871 34.1027Z"
                  fill="#CD6116"
                />
                <path
                  d="M14.9146 29.8576L19.2335 38.3111L19.0871 34.1027L14.9146 29.8576ZM36.9302 34.1027L36.7472 38.3111L41.0845 29.8576L36.9302 34.1027ZM25.2178 30.317L24.2479 35.6281L25.4557 41.8947L25.7302 33.6433L25.2178 30.317ZM30.7995 30.317L30.3054 33.6249L30.525 41.8947L31.7511 35.6281L30.7995 30.317Z"
                  fill="#E4751F"
                />
                <path
                  d="M31.7511 35.6281L30.5249 41.8947L31.4034 42.5012L36.7472 38.3112L36.9302 34.1028L31.7511 35.6281ZM19.087 34.1028L19.2334 38.3112L24.5772 42.5012L25.4557 41.8947L24.2478 35.6281L19.087 34.1028Z"
                  fill="#F6851B"
                />
                <path
                  d="M31.8426 48.2716L31.8975 46.5625L31.44 46.1582H24.5406L24.1197 46.5625L24.1563 48.2716L18.3916 45.5334L20.4047 47.1873L24.4857 50.0358H31.4949L35.5942 47.1873L37.6073 45.5334L31.8426 48.2716Z"
                  fill="#C0AD9E"
                />
                <path
                  d="M31.4034 42.5012L30.525 41.8947H25.4557L24.5773 42.5012L24.1198 46.5625L24.5407 46.1582H31.44L31.8975 46.5625L31.4034 42.5012Z"
                  fill="#161616"
                />
                <path
                  d="M49.7773 21.0549L51.3328 13.557L49.0086 6.59204L31.4034 19.7134L38.1746 25.4655L47.7459 28.2772L49.8688 25.7962L48.9537 25.1347L50.4178 23.7931L49.2831 22.911L50.7472 21.79L49.7773 21.0549ZM4.66614 13.557L6.22169 21.0549L5.23346 21.79L6.69751 22.911L5.58117 23.7931L7.04522 25.1347L6.13019 25.7962L8.23477 28.2772L17.806 25.4655L24.5773 19.7134L6.97202 6.59204L4.66614 13.557Z"
                  fill="#763D16"
                />
                <path
                  d="M47.7458 28.2772L38.1746 25.4655L41.0844 29.8576L36.7472 38.3111L42.457 38.2376H50.9668L47.7458 28.2772ZM17.806 25.4655L8.23473 28.2772L5.05042 38.2376H13.5419L19.2334 38.3111L14.9145 29.8576L17.806 25.4655ZM30.7994 30.317L31.4034 19.7134L34.1851 12.1603H21.8321L24.5772 19.7134L25.2177 30.317L25.4373 33.6617L25.4556 41.8947H30.5249L30.5615 33.6617L30.7994 30.317Z"
                  fill="#F6851B"
                />
              </svg>
            ) : connector.name === "WalletConnect" ? (
              <svg
                width="56"
                height="57"
                viewBox="0 0 56 57"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="28" cy="28.5" r="28" fill="#3B99FC" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M39.0237 21.3922C32.9354 15.3137 23.0644 15.3137 16.9762 21.3922L16.1719 22.1952C15.8675 22.4991 15.8675 22.9919 16.1719 23.2958L18.6785 25.7984C18.8307 25.9503 19.0774 25.9503 19.2296 25.7984L20.3095 24.7202C24.5568 20.4797 31.4431 20.4797 35.6904 24.7202L36.6987 25.727C36.8509 25.8789 37.0977 25.8789 37.2499 25.727L39.7564 23.2244C40.0609 22.9205 40.0609 22.4277 39.7564 22.1238L39.0237 21.3922ZM46.4383 28.795L44.2075 26.5678C43.903 26.2638 43.4095 26.2638 43.1051 26.5678L35.9659 33.6956C35.8898 33.7715 35.7664 33.7715 35.6903 33.6956L28.551 26.5676L28.551 26.5676C28.2466 26.2637 27.753 26.2637 27.4486 26.5676L20.3097 33.6956C20.2336 33.7716 20.1102 33.7716 20.0341 33.6956L12.8948 26.5676C12.5903 26.2637 12.0968 26.2637 11.7924 26.5676L9.56156 28.7949C9.25715 29.0988 9.25715 29.5916 9.56156 29.8955L19.6208 39.9387C19.9252 40.2426 20.4187 40.2426 20.7231 39.9387L27.8622 32.8109C27.9383 32.7349 28.0617 32.7349 28.1378 32.8109L35.277 39.9387C35.5814 40.2426 36.075 40.2426 36.3794 39.9387L46.4383 29.8956C46.7427 29.5917 46.7427 29.099 46.4383 28.795Z"
                  fill="white"
                />
              </svg>
            ) : (
              <img style={{ width: 56, height: 57 }} src={connector.icon} />
            )}
            <div style={{ marginTop: 10 }}></div>
            <p
              className="text-truncate"
              style={{ color: " #B9DCEA !important", cursor: "pointer" }}
            >
              {connector.name}
            </p>
            <button
              className="mt-3"
              key={connector.uid}
              onClick={() => handleConnect(connector)}
            >
              Connect
            </button>
          </div>
        ))} */}

      </div>
      <div className="help pt-4">
        For more information: {" "}
        <a href="https://t.me/cosmic_network_portal">
          {" "}
          Join Telegram
        </a>
      </div>
    </div>
  );
};

export default ConnectWallet;
